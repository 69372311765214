
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    IonButtons,
    IonInput,
    onIonViewWillEnter,
    menuController,
    modalController,
} from "@ionic/vue";
import { refresh, menu, arrowBack, arrowForward } from "ionicons/icons";

import { computed, onBeforeUnmount, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

import { QrStream } from "vue3-qr-reader";

import moment from "moment";

import { openToast } from "../services/toast";

import apiPresenze from "../services/presenze";

import Reperibilita from "../components/Reperibilita.vue";
import ModalCreaPresenza from "../components/ModalCreaPresenza.vue";

export default {
    name: "Presenze",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        IonInput,
        QrStream,
    },
    setup() {
        const router = useRouter();
        const loading = ref(false);
        const richieste = ref([]);

        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;
        const user = JSON.parse(localStorage.getItem("userInfo"));

        //Nuovi campi per timbratura
        const presenze = ref([]);
        const entrataTimbrata = ref(false);
        const uscitaTimbrata = ref(false); //opposto del timbra entrata

        const statusShowItem = ref({});
        //Real time clock
        const timer = ref(null);
        const time = ref(null);

        /**
         * Menu handler
         */
        const openMenu = () => {
            menuController.open("app-menu");
        };

        // update the time every second
        function interval() {
            setInterval(() => {
                time.value = Intl.DateTimeFormat(navigator.language, {
                    hour: "numeric",
                    minute: "numeric",
                }).format();
            }, 1000);
        }

        interval();

        const options = { day: "2-digit", month: "long", year: "numeric" };
        const todayDate = new Date().toLocaleString("it-IT", options);

        /**
         * ! Apre modale per creare presenza completa con data ed ora inizio e fine + selezione cliente
         */
        async function createPresenza() {
            const modal = await modalController.create({
                component: ModalCreaPresenza,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail.data);
                    presenze.value = [detail.data, ...presenze.value];
                    openToast("Presenza inserita correttamente", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * ! Gestione stampa cliente o meno se associato alla presenza
         */
        function printCustomer(timbratura) {
            if (timbratura.customers_company) {
                const trimmedString =
                    timbratura.customers_company.length > 35 ? timbratura.customers_company.substring(0, 32) + "..." : timbratura.customers_company;
                return ` - ${trimmedString}`;
            } else if (timbratura.customers_name && timbratura.customers_last_name) {
                const reference = `${timbratura.customers_name} ${timbratura.customers_last_name}`;
                const trimmedString = reference.length > 35 ? reference.substring(0, 32) + "..." : reference;
                return ` - ${trimmedString}`;
            }
        }

        /**
         * ! Gestione straordinario manuale
         */
        const showStraordinario = ref(false);
        const selectedPresenza = ref(null);

        function apriStraordinario(presenza) {
            if (presenza.presenze_data_fine || presenza.presenze_data_fine == "") {
                selectedPresenza.value = { ...presenza };
                showStraordinario.value = true;
            } else {
                openToast("Potrai inserire lo staordinario solamente dopo aver chiuso la presenza", "toast_danger");
            }
        }
        function chiudiStraordinario() {
            selectedPresenza.value = null;
            showStraordinario.value = false;
        }

        function salvaStraordinario() {
            if (selectedPresenza.value && selectedPresenza.value.presenze_straordinario > 0) {
                const presenzaId = selectedPresenza.value.presenze_id;
                const straordinario = selectedPresenza.value.presenze_straordinario;

                apiPresenze
                    .saveStraordinario(presenzaId, straordinario)
                    .then((response) => {
                        //console.log(response);
                        if (response.data.status === 0) {
                            const res = response.data.data[0];
                            //Aggiorno presenza appena modificata e chiudo la modale
                            const presenzaModificata = presenze.value.find((element) => element.presenze_id === res.presenze_id);
                            if (presenzaModificata) {
                                presenzaModificata.presenze_straordinario = res.presenze_straordinario;
                                openToast("Straordinario inserito correttamente", "toast_success");
                                showStraordinario.value = false;
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        openToast("Errore durante l'inserimento dello straordinario", "danger");
                    });
            } else {
                openToast("Devi inserire un valore maggiore di 0 per salvare lo straordinario", "toast_danger");
            }
        }

        /**
         * ! Apre pagiuna per inserire la reperibilità e visualizzare quelle inserite in elenco,
         * ! Se comunicata nell'ultima ora mostro button per poterla eliminare
         */
        async function openReperibilita() {
            const modal = await modalController.create({
                component: Reperibilita,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //richieste.value = [detail.data, ...richieste.value];
                    openToast("Reperibilita comeunicata correttamente", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * Imposta lo straordinario sulla singola presenza
         * @param presenzaId id presenza su cui modificare straordinario
         * @param ore_straoardinario ore di straoarindario effettuate
         */
        /*     function setStraordinario(presenzaId, ore_straoardinario) {
      if (ore_straoardinario != 0) {
        apiPresenze
          .saveStraordinario(presenzaId, ore_straoardinario)
          .then((response) => {
            //console.log(response);
            if (response.data.status === 0) {
              const res = response.data.data[0];
              //Cerco la presenza appena modificata e la aggiorno
              const presenzaModificata = presenze.value.find((element) => element.presenze_id === res.presenze_id);
              if (presenzaModificata) {
                presenzaModificata.presenze_straordinario = res.presenze_straordinario;

                openToast("Straordinario inserito correttamente", "toast_success");
              }
            }
          })
          .catch((error) => {
            console.error(error);
            openToast("Errore durante l'inserimento dello straordinario", "toast_danger");
          });
      } else {
        openToast("Devi inserire un valore maggiore di 0 per salvare lo straordinario", "toast_danger");
      }
    } */

        //2) CONTROLLO QUALI PULSANTI DISABILITARE
        /**
         * Se ho timbratura di oggi senza presenze_ora_fine vuol dire che ho timbrato SOLO l'entrata -> disabilito entrata
         * Se ho timbratura di oggi ed ha anche presenze_ora_fine vuol dire che ho timbrato entrati -> riabilito entrata per succissivo utilizzo
         */
        function checkTimbrature() {
            const today = moment().format("YYYY-MM-DD");
            const ultimaTimbratura = presenze.value[0];
            const entrata = moment(ultimaTimbratura.presenze_data_inizio).format("YYYY-MM-DD");
            //mi prendo l'ultima timbratura effettuata e se NON ha end time allora ho fatto solo entrata quindi disabilito entrata
            //altrimenti riabilito entrata perchè le ho già fatte entrambe (potrebbe essere stata chiusa dal cron e non da me)
            if ((entrata === today && ultimaTimbratura.presenze_ora_fine === null) || ultimaTimbratura.presenze_ora_fine === "") {
                entrataTimbrata.value = true;
            } else {
                entrataTimbrata.value = false;
            }
        }

        //1) SCARICO TIMBRATURE EFFETTUATE e controllo se disabilitare entrata
        async function loadTimbrature() {
            loading.value = true;
            apiPresenze
                .getTimbrature(userID)
                .then((response) => {
                    //console.log("TIMBRATURE EFFETTUATE: ", response);
                    presenze.value = response;
                    if (response.length > 0) {
                        checkTimbrature();
                    } else {
                        entrataTimbrata.value = false;
                        uscitaTimbrata.value = false;
                    }
                    //checkTimbrature(response);
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la richiesta delle presenze", "danger");
                })
                .finally(() => {
                    statusShowItem.value = {};
                    loading.value = false;
                });
        }

        /**
         * QR Code handler
         */
        const state = reactive({
            data: null,
        });
        const showQrCode = ref(false);
        const confirmationText = ref("");
        const disabledQrCodeButton = ref(false);

        function onDecode(data) {
            state.data = data;
            if (data) {
                //stampo informazioni per avvisare il dipendente della timbratura che sta per effettuare
                console.log(data);
                checkTimbrature();
                const orario = moment().format("HH:mm");
                //Sto timbrando uscita
                if (entrataTimbrata.value === true) {
                    confirmationText.value = `Cliccando su "Salva" confermi di essere ${user.dipendenti_nome} ${user.dipendenti_cognome} e di timbrare l'uscita alle ore ${orario}`;
                } else {
                    //sto timbrando entrata
                    confirmationText.value = `Cliccando su "Salva" confermi di essere ${user.dipendenti_nome} ${user.dipendenti_cognome} e di timbrare l'entrata alle ore ${orario}`;
                }
            }
        }

        function openQrCode() {
            showQrCode.value = true;
        }
        function chiudiQrCode() {
            showQrCode.value = false;
            state.data = null;
            confirmationText.value = "";
            disabledQrCodeButton.value = false;
        }
        function handleQrCodeTimbratura() {
            console.log(state.data);
            if (state.data) {
                let qrValue = state.data.slice(-5);
                disabledQrCodeButton.value = true;

                apiPresenze
                    .scanQrCode(qrValue, dipendenteID)
                    .then((response) => {
                        if (response.data.status === 0) {
                            //error
                            openToast(response.data.txt, "toast_danger");
                        } else {
                            if (response.data.tipo === "entrata") {
                                //aggiungo nuova presenza
                                presenze.value = [response.data.data, ...presenze.value];
                                entrataTimbrata.value = true;
                            } else {
                                //devo modificare presenza e chiudo la modale
                                const presenzaModificata = presenze.value.find((element) => element.presenze_id === response.data.data.presenze_id);
                                if (presenzaModificata) {
                                    presenzaModificata.presenze_data_fine = response.data.data.presenze_data_fine;
                                    presenzaModificata.presenze_ora_fine = response.data.data.presenze_ora_fine;
                                }
                                entrataTimbrata.value = false;
                            }
                            openToast(response.data.txt, "toast_success");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        openToast("Errore durante la scansione del codice QR", "toast_danger");
                    })
                    .finally(() => {
                        state.data = null;
                        showQrCode.value = false;
                        qrValue = null;
                        confirmationText.value = "";
                        disabledQrCodeButton.value = false;
                    });
            } else {
                openToast("Inquadra il codice QR prima di salvare la presenza", "toast_danger");
            }
        }

        /**********************************************************************************************************************/
        //5) UTILS
        /**
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }
        /**
         * Format date from YYYY/MM/DD HH:mm:ss to HH:mm
         */
        function timeFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("HH:mm");
            } else {
                return "-";
            }
        }

        //Set correct background for richieste status
        const statoReperibilita = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 0) {
                    //attesa approvazione
                    className = "badge_danger";
                } else if (statusId == 1) {
                    //approvato
                    className = "badge_success";
                }
                return className;
            };
        });

        /**********************************************************************************************************************/
        //4A) TIMBRATURA USCITA - Edit della presenza di oggi e senza presenze_ora_fine
        function timbraUscita(uscita_data, uscita_ora) {
            const ora_uscita = uscita_ora;
            const data_uscita = uscita_data;
            const data = new FormData();
            data.append("presenze_dipendente", userID);
            data.append("presenze_data_inizio", data_uscita);
            data.append("presenze_ora_fine", ora_uscita);

            apiPresenze
                .chiudiPresenza(userID, ora_uscita)
                .then((response) => {
                    console.log(response);
                    if (response.data.status === 0) {
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        openToast(response.data.txt, "toast_success");
                        //Cerco la presenza appena modificata, la aggiorno e abilito di nuovo btn entrata
                        const presenzaDaChiudure = presenze.value.find((element) => element.presenze_id === response.data.uscita.presenze_id);
                        const presenzaChiusa = response.data.uscita;
                        if (presenzaDaChiudure) {
                            //console.log(presenzaDaChiudure);
                            presenzaDaChiudure.presenze_data_fine = presenzaChiusa.presenze_data_fine;
                            presenzaDaChiudure.presenze_ora_fine = presenzaChiusa.presenze_ora_fine;
                            presenzaDaChiudure.presenze_straordinario = parseFloat(presenzaChiusa.presenze_straordinario).toFixed(2);

                            entrataTimbrata.value = false;
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la timbratura", "toast_danger");
                });
        }
        //4) SALVO PRESENZA USCITA
        /**
         * Se ho effettuato una timbratura in entrata (la presenza SENZA ora_fine è quella di oggi)
         * allora posso fare una timbratura in uscita --> edit presenze impostando ora e data fine
         * altrimenti mostro errore
         */
        function handleTimbraUscita() {
            const data_uscita = moment().format("YYYY-MM-DD");
            const ora_uscita = moment().format("HH:mm");
            //Se ho già timbrato una entrata allora la modifica salva l'uscita altrimenti errore
            //timbraEntrata(data_ordierna, ora_entrata);
            timbraUscita(data_uscita, ora_uscita);
        }

        /* ===================================================================================================================== */
        //3A) SALVA PRESENZA IN ENTRATA
        function timbraEntrata(entrata_data, entrata_ora) {
            const ora_entrata = entrata_ora;
            const data_entrata = entrata_data;

            apiPresenze
                .timbraEntrata(userID, ora_entrata)
                .then((response) => {
                    //console.log(response);
                    if (response.data.status === 0) {
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        openToast(response.data.txt, "toast_success");
                        presenze.value = [response.data.entrata, ...presenze.value];
                        entrataTimbrata.value = true;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la timbratura", "toast_danger");
                });
        }

        //3) GESTISCE PRESENZA IN ENTRATA ( --------> [HR - APP] - Presenza in entrata ed orari calendario )
        function handleTimbraEntrata() {
            const data_ordierna = moment().format("YYYY-MM-DD");
            const ora_entrata = moment().format("HH:mm");
            //Se nel mio profilo c'è un orario di inizio e fine per la giornata di oggi salva l'entrata altrimenti errore
            timbraEntrata(data_ordierna, ora_entrata);
        }

        /* ===================================================================================================================== */
        //1) SCARICO TIMBRATURE EFFETTUATE e controllo se disabilitare entrata
        onMounted(() => {
            loadTimbrature();
        });

        /* Ad ogni accesso alla pagina controllo se disabilitare il pulsante */
        onIonViewWillEnter(() => {
            loadTimbrature();
            /** Se ho timbrato entrata disabilito entrata
             * Se non ho timbrato entrata disabilito uscita
             */
        });

        onBeforeUnmount(() => {
            clearInterval(timer.value);
        });

        return {
            loading,
            dateFormat,
            timeFormat,
            refresh,
            richieste,
            loadTimbrature,
            //Presenze
            presenze,
            handleTimbraEntrata,
            handleTimbraUscita,
            entrataTimbrata,
            uscitaTimbrata,
            //Reperibilità
            statoReperibilita,
            statusShowItem,
            //setStraordinario,
            //reperibilita,
            menu,
            openMenu,
            time,
            arrowBack,
            arrowForward,
            //Pagina reperibilita
            openReperibilita,
            //Straordinario
            apriStraordinario,
            chiudiStraordinario,
            selectedPresenza,
            showStraordinario,
            salvaStraordinario,
            todayDate,
            //QR code data,
            openQrCode,
            showQrCode,
            QrStream,
            ...toRefs(state),
            onDecode,
            chiudiQrCode,
            handleQrCodeTimbratura,
            confirmationText,
            disabledQrCodeButton,
            //Creazione presenza completa
            createPresenza,
            printCustomer,
        };
    },
};
